@import '@we-make-websites/styles-lib/src/config/configuration';
.collectionListing {
  $parent: &;
  --slider-height: 100%;
  --constrained-offset: var(--spacing-2xs);
  --slider-spacing: var(--spacing-2xs);
  --itemview-transform: translateX(var(--constrained-offset));
  margin-block: var(--spacing-xl);

  &__grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 5px;
    grid-row-gap: 5px;
    margin-inline-end: calc(-1 * var(--margin-mobile));
    margin-inline-start: calc(-1 * var(--margin-mobile));
  }

  &__message {
    height: 114vw;
    position: relative;
    overflow: hidden;
  }

  &__messageBackground {
    width: 100%;
    height: 100%;
    position: absolute;
    object-position: center bottom;
  }

  &__messageForeground {
    position: absolute;
    top: 0;
    left: 0;
    padding: var(--spacing-xl) var(--margin-mobile) var(--spacing-3xl);
  }

  &__text {
    margin-block-start: var(--spacing-s);
  }

  &__button {
    margin-top: var(--spacing-l);
  }

  &__product {
    &#{&} {
      width: 9.875rem;
      height: var(--slider-height);
    }
  }

  &__sliderContainer {
    overflow: hidden;

    [data-previous-button],
    [data-next-button] {
      z-index: 1;
    }

    [data-slider-slide]:last-child [data-slider-itemview] {
      padding-right: var(--constrained-offset);
    }
  }

  &__slider {
    width: 100%;
  }

  &__carousel {
    --arrow-top-spacing: 117px;
  }

  @include mq($from: s) {
    &__messageForeground {
      padding: var(--spacing-3xl) var(--spacing-10xl) var(--spacing-3xl)
        var(--spacing-3xl);
    }
  }

  @include mq($from: m) {
    &__grid {
      margin-inline-end: calc(-1 * var(--margin-tablet));
      margin-inline-start: calc(-1 * var(--margin-tablet));
    }

    &__product {
      &#{&} {
        width: 22.25rem;
      }
    }
  }

  @include mq($from: l) {
    --itemview-transform: translateX(0);
    margin-block-end: var(--spacing-5xl);
    margin-block-start: var(--spacing-5xl);

    &__carousel {
      --arrow-top-spacing: 218px;
    }

    &__grid {
      display: grid;
      grid-template-columns: 33.375rem 1fr;
      grid-template-rows: var(--slider-height);
      margin-inline-end: calc(-1 * var(--margin-desktop));
      margin-inline-start: calc(-1 * var(--margin-desktop));
    }

    &__message {
      height: 100%;
    }

    &__product {
      height: 100%;
    }
  }
}
